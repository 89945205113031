import TYPES from '@/types';
import Vue from 'vue';

// Application
import GetSingleUseScheduleLinkQuery
  from '@/modules/calendly/application/queries/get_single_use_schedule_link-query';

import GetPersonQueryService
  from '@/modules/my-investment/person/application/queries/get-my-investment-person-query';

import SearchMyInvestmentEmailsQuery
  from '@/modules/my-investment/emails/application/queries/search-my-investment-emails-query';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class SpecialDepositCalendlyViewModel {
  @Inject(TYPES.GET_SINGLE_USE_SCHEDULE_LINK_QUERY)
  private readonly get_single_use_schedule_link_query!: GetSingleUseScheduleLinkQuery;

  @Inject(TYPES.GET_INVESTMENT_PERSON_QUERY)
  private readonly get_person_query!: GetPersonQueryService;

  @Inject(TYPES.SEARCH_MY_INVESTMENT_EMAILS_QUERY)
  private readonly search_my_investment_email_query!: SearchMyInvestmentEmailsQuery;

  private readonly view!: Vue;

  @Inject(TYPES.NOTIFIER)
  private readonly messageNotifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  readonly i18n_namespace = 'components.allianz-dashboard.deposits.schedule-a-call';

  public constructor(view: Vue) {
    this.view = view;
  }

  single_use_schedule_link = '';

  prefill = {
    name: '',
    email: '',
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  async getSingleUseScheduleLink() {
    try {
      const { booking_url } = await this.get_single_use_schedule_link_query.execute();
      this.single_use_schedule_link = booking_url;
    } catch {
      this.messageNotifier.showErrorNotification(this.translate('error_get_single_use_schedule_link'));
    }
  }

  getPrefillData = async () => {
    const { name } = this.get_person_query.execute();
    let email = '';
    const emails = await this.search_my_investment_email_query.execute();
    const email_entity = emails.find((item) => item.customer_email_default);
    if (email_entity) {
      email = email_entity.email.email_address;
    }

    this.prefill = {
      name,
      email,
    };
  }

  isCalendlyEvent(event: MessageEvent) {
    return event.data.event && event.data.event.indexOf('calendly') === 0;
  }

  handleCalendlyEvent(event: MessageEvent) {
    if (this.isCalendlyEvent(event)) {
      if (event.data.event === 'calendly.event_scheduled') {
        this.view.$props.synced_is_open = false;
      }
    }
  }

  addEventListeners = () => {
    window.addEventListener('message', this.handleCalendlyEvent);
  }

  removeEventListeners = () => {
    window.removeEventListener('message', this.handleCalendlyEvent);
  }

  loadCalendlyScript = () => {
    const calendly_script = document.createElement('script');
    calendly_script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
    document.head.appendChild(calendly_script);
  }

  initialize = async () => {
    this.addEventListeners();
    await this.getSingleUseScheduleLink();
    await this.getPrefillData();
    this.loadCalendlyScript();
  }

  destroy = async () => {
    this.removeEventListeners();
  }
}
