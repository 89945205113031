


































import {
  Vue,
  Component,
  PropSync,
} from 'vue-property-decorator';
import SpecialDepositCalendlyViewModel
  from '@/vue-app/view-models/allianz-dashboard/deposits/special-deposit-calendly-view-model';

declare global {
  interface Window {
    Calendly: any;
  }
}

@Component({ name: 'CalendlyModal' })
export default class CalendlyModal extends Vue {
  @PropSync('isOpen', { type: Boolean })
  synced_is_open!: boolean;

  special_deposit_calendly_view_model = Vue.observable(
    new SpecialDepositCalendlyViewModel(this),
  );

  closeModal() {
    this.synced_is_open = false;
  }

  initializeCalendlyWidget = () => {
    setTimeout(() => {
      window.Calendly.initInlineWidget({
        url: this.special_deposit_calendly_view_model.single_use_schedule_link,
        parentElement: document.getElementById('calendly-modal'),
        prefill: this.special_deposit_calendly_view_model.prefill,
      });
    }, 1000);
  }

  async created() {
    await this.special_deposit_calendly_view_model.initialize();
    this.initializeCalendlyWidget();
  }

  destroyed() {
    const calendly_modal = document.getElementById('calendly-modal');
    if (calendly_modal) {
      calendly_modal.remove();
    }
    this.special_deposit_calendly_view_model.destroy();
  }
}

