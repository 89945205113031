var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":_vm.$vuetify.breakpoint.md
      ? '40%'
      : _vm.$vuetify.breakpoint.lg
        ? '30%'
        : _vm.$vuetify.breakpoint.sm
          ? '60%'
          : _vm.$vuetify.breakpoint.xl
          ? '25%'
          : '70%',"content-class":_vm.$style['modal-content'],"fullscreen":_vm.$vuetify.breakpoint.xs},model:{value:(_vm.synced_is_open),callback:function ($$v) {_vm.synced_is_open=$$v},expression:"synced_is_open"}},[_c('v-card',{attrs:{"rounded":"lg","height":"100%"}},[_c('v-card-text',{staticClass:"h-full"},[_c('v-btn',{attrs:{"icon":"","absolute":"","top":"","right":""},on:{"click":_vm.closeModal}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-container',{staticClass:"h-full px-4 px-md-1 pt-5 pt-md-13 pb-1 pb-md-13"},[_c('div',{class:_vm.$style['calendly-modal'],attrs:{"id":"calendly-modal"}})])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }